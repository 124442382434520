<template>
  <div class="order">
    <h3>订单确认</h3>
    <table>
      <thead>
        <tr>
          <th>商品</th>
          <th>单价</th>
          <th>数量</th>
          <th>小计</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in items" :key="index">
          <td>{{ item.name.slice(0, 8) }}</td>
          <td>￥{{ item.price }}</td>
          <td>{{ item.qty }}</td>
          <td>￥{{ item.qty * item.price }}</td>
        </tr>
      </tbody>
    </table>
    <div class="info">
      <div>邮箱：{{ email }}</div>
      <div>联系手机：{{ phone }}</div>
      <div>总金额：<span class="total">￥{{ total }}</span></div>
    </div>
    <div class="note">请将以上信息复制到支付的备注里，不备注不发货</div>
    <div>支付方式：{{ method }}</div>
    <div class="code">
      <!-- <img :src="codeUrl" alt="支付二维码"> -->
      <img :src="method === 'wechat' ? './img/2.png' : './img/3.png'" alt="支付二维码">
      <div class="tip">请使用{{ method === 'alipay' ? '支付宝' : '微信' }}扫码支付</div>
    </div>
    <!-- <button class="btn confirm" @click="confirm">确认购买</button> -->
    <button class="btn cancel" @click="$emit('close')">取消</button>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    codeUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    confirm() {
      this.$emit('buy');
    }
  }
};
</script>

<style scoped>
/* 样式可根据自己的需要进行修改 */
.order {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  max-height: 60%;
  overflow: auto;
  background-color: #8298c1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

th,
td {
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
}

.info {
  margin-bottom: 10px;
  display: flex;
}
.info > div {
  width:30%;
}

.total {
  font-weight: bold;
}

.code {
  text-align: center;
}

.code img {
  width: 240px;
  height: 240px;
  margin-bottom: 10px;
}

.tip {
  font-size: 14px;
  color: #fff;
}

button {
  display: block;
  margin: 10px auto;
  padding: 8px 16px;
  font-size: 16px;
  color: #fff;
  background-color: #409eff;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
}

button.confirm {
  background-color: #67c23a;
}

button:hover {
  opacity: 0.8;
}
.note{
  color:#ffb2b2;
  font-size: 18px;
}
</style>