<template>
  <div class="home">
    <h1>openAI国内外账号</h1>
    <div class="item" v-for="(item,index) in items" :key="index">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="44" fill="none" class="aiicon" style="
    margin-top: -7%;
    margin-left: 2%;
    scale: 1.4;
"><defs><path id="chatgpt_svg__a" d="M0 0h43v43.58H0z"></path></defs><g><mask id="chatgpt_svg__b" fill="#fff"><use xlink:href="#chatgpt_svg__a"></use></mask><g mask="url(#chatgpt_svg__b)"><path fill-rule="evenodd" opacity="0.27" d="M40.17 17.84c.36-1.11.55-2.27.55-3.43 0-1.93-.51-3.83-1.49-5.49a10.98 10.98 0 0 0-9.52-5.51c-.77 0-1.55.08-2.3.24A10.868 10.868 0 0 0 19.29 0h-.1c-4.76 0-8.98 3.07-10.45 7.6-3.06.63-5.71 2.55-7.26 5.27a10.993 10.993 0 0 0 1.35 12.87c-.36 1.11-.55 2.27-.55 3.43 0 1.93.51 3.83 1.49 5.49a10.97 10.97 0 0 0 11.82 5.27c2.06 2.32 5.02 3.65 8.12 3.65h.1c4.76 0 8.99-3.07 10.45-7.61a10.82 10.82 0 0 0 7.26-5.26 10.995 10.995 0 0 0-1.35-12.87ZM18.817 38.695c-.09.05-.17.1-.26.15a8.145 8.145 0 0 0 5.22 1.89h.01c4.5-.01 8.15-3.67 8.16-8.17v-10.13a.153.153 0 0 0-.07-.1l-3.67-2.12v12.24c0 .51-.27.98-.72 1.23l-8.67 5.01Zm-1.424-2.472 8.77-5.06c.04-.03.06-.07.06-.11h-.01v-4.24l-10.59 6.12c-.44.25-.98.25-1.42 0l-8.68-5.01c-.08-.05-.2-.12-.26-.16a8.19 8.19 0 0 0 .97 5.47 8.18 8.18 0 0 0 7.08 4.08c1.43 0 2.84-.37 4.08-1.09Zm-9.187-25.21v-.3c-1.79.66-3.3 1.93-4.25 3.58a8.226 8.226 0 0 0-1.09 4.08c0 2.92 1.55 5.61 4.08 7.07l8.77 5.07c.04.02.09.02.12-.01l3.67-2.12-10.59-6.11c-.44-.25-.71-.72-.71-1.23v-10.03Zm27.849 7.117-8.77-5.07a.126.126 0 0 0-.12.01l-3.67 2.12 10.59 6.12c.44.25.71.71.71 1.22v10.33a8.168 8.168 0 0 0 5.35-7.66 8.16 8.16 0 0 0-4.09-7.07Zm-19.22-5.718a.16.16 0 0 0-.05.11v4.24l10.59-6.12c.22-.12.47-.19.72-.19s.49.07.71.19l8.68 5.02c.08.05.17.1.25.15.08-.46.12-.92.12-1.38 0-4.51-3.66-8.17-8.17-8.17-1.43 0-2.83.38-4.08 1.09l-8.77 5.06ZM19.22 2.85c-4.51 0-8.17 3.65-8.17 8.16v10.13c.01.05.03.08.07.1l3.67 2.12.01-12.23v-.01c0-.5.27-.97.71-1.22l8.68-5.01c.07-.05.19-.11.25-.15a8.145 8.145 0 0 0-5.22-1.89ZM16.783 24.51l4.72 2.73 4.72-2.73v-5.45l-4.72-2.72-4.72 2.73v5.44Z" style="fill: rgb(139, 202, 224);"></path></g></g></svg>
      <div class="name">
        <div>{{ item.name }}</div>
        </div>
      <div class="price">￥{{ item.price }}</div>
      <div class="quantity">
        <input type="number" v-model.number="item.qty" min="0" @change="updateTotal">
        <span class="total">￥{{ item.qty * item.price }}</span>
      </div>
    </div>
    <div class="form">
      <label for="email">邮箱：</label>
      <input type="email" id="email" v-model.trim="email" placeholder="请输入邮箱">
      <label for="method">支付方式：</label>
      <select id="method" v-model="method">
        <option value="">请选择</option>
        <option value="wechat">微信支付</option>
        <option value="alipay">支付宝</option>
      </select>
      <label for="phone">联系手机：</label>
      <input type="tel" id="phone" v-model.trim="phone" placeholder="请输入手机号">
          <button class="btn buy" @click="showOrder = true" :disabled="total == 0">购买</button>
    <label for="email">联系卖家：</label>
    <img src="/img/4.png" alt="卖家二维码">
    </div>
    

    <Order
      v-if="showOrder"
      :items="items"
      :total="total"
      :email="email"
      :method="method"
      :phone="phone"
      :codeUrl="codeUrl"
      @buy="orderBuyHandler"
      @close="showOrder = false"
    />
  </div>
</template>

<script>
import Order from './goodsOrder.vue'
export default {
  data() {
    return {
      items: [
        { name: '国内国外通用账号，发官方账号密码，key，权限密码，含记忆设置和语境切换功能', price: 59.9, qty: 0 },
        { name: '官方GPT4独享账号，需配合工具使用', price: 259.9, qty: 0 },
        { name: '官方国外访问账号，需配合工具使用', price: 29.9, qty: 0 },
        { name: '稳定GPT4年度账号，长期使用，无需频繁购买', price: 599.9, qty: 0 }
      ],
      total: 0,
      email: '',
      method: '',
      phone: '',
      showOrder: false,
      codeUrl: '',
      // wechatCodeUrl:'',
      // alipayCodeUrl:''
    }
  },
  methods: {
    updateTotal() {
      let sum = 0;
      for (let i = 0; i < this.items.length; i++) {
        sum += this.items[i].qty * this.items[i].price;
      }
      this.total = sum;
    },
    buy() {
      // const wechatCodeUrl = './assets/2.png'
      // const alipayCodeUrl = './assets/2.png'
      if (this.method === 'wechat') {
        this.codeUrl = 'https://www.abc.com/pay?amount=' + this.total; // 生成支付二维码url
        // this.codeUrl = wechatCodeUrl; // 生成支付二维码url
        this.showOrder = true;

      } else {
        alert('请填写完整信息！');
      }
    },
    orderBuyHandler() {
      alert('购买成功！');
      this.showOrder = false;
    }
  },
  components: {
    Order
  }
};
</script>

<style scoped>
/* 样式可根据自己的需要进行修改 */
.home {
  margin: 20px;
  font-size: 14px;
  width: 80%;
  height: 80%;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    height: 200px;
    /* padding: 15px; */
    /* top: 5px; */
    border: 1px #0a2c59 solid;
    background: #092a57;
    border-radius: 30px 3px 30px 3px;
    box-shadow: #2996ad 0px 5px;
}

.name {
  flex: 1;
  font-size: 32px;
}
.name div{
  width: 100%;
  margin-right: 8px;
  margin-left: 131px;
}

.price {
  width: 60px;
  text-align: center;
  margin-left: 30%;
}

.quantity {
  flex: 1;
  display: flex;
  align-items: center;
}

input[type="number"] {
  width: 50px;
  height: 22px;
  text-align: center;
}

.total {
  margin-left: 10px;
}

.form {
  margin: 10px 0px 10px 50px;
  float: left;
  width: 100%;
  text-align: left;
}
.form img{
  width: 100px;
  position: absolute;
  margin-top: -24px;
}

label {
  margin-left: 25px;
}
.btn.buy {
  height: 28px;
  width: 10%;
  background: #ff9c07;
  border-radius: 5px;
  color: aliceblue;
  margin-top: 10px;
  margin-left: 173px;
  border-style: unset;
  box-shadow: #777 0px 3px;
}
button:disabled {
  color: rgb(61, 60, 60) !important;
  background: #207d9c7a !important;
  box-shadow: #777 0px 3px  !important;
}

input {
  width: 200px;
  height: 22px;
}

select,input {
  height: 28px;
  width: 200px;
  border-radius: 5px;
  background: darkturquoise;
  border-style: unset;
}
h1{
  height: 42px;
  color: #186088;
  margin-top: -48px;
}

@media screen and (max-width: 1080px) {
.name {
    font-size: 14px;
}

.aiicon{
  margin-top: -19%!important;
  margin-left: 1%!important;
  scale: 0.5!important;
}
.name div{
  width: 200%;
  margin-right: 8px;
  margin-left: 20px;
}
.item{
  height: 124px;
}
h1{
  color:#e0edf5
}
.btn.buy{
  width:44%;
  margin-top: 34px;
  margin-left: 151px;
}
label {
  margin-left: 0px;
  display: flex;
  color: #092a57;
  margin-top: 4px;
}
.order{
  width: 81%;
  max-height: 80%;
}
.form img{
  margin-top: 5px;
  padding-bottom: 20px;
}
}
</style>